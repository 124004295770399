.logoutBtn {
    background-color: #333;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.logoutBtn:hover {
    background-color: #555;
    cursor: pointer;
}

.logoutBtn img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.loginBtn {
    width: 30vh;
    height: 6vh;
    background-color: #333;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginBtn:hover {
    background-color: #555;
    cursor: pointer;
}
