.inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    margin-top: auto;
    justify-content: center;
    align-items: center;
}

.chatHistory {
    color: white;
    width: 95%;
    height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
}

.sentMessage {
    max-width: 80%;
    min-width: 30%;
    min-height: 50px;
    background-color: rgb(188, 238, 255);
    color: black;
    border-radius: 20px 20px 0 20px;
    margin-left: auto;
    text-align: left;
    padding: 8px;
    margin-right: 10px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.receivedMessage {
    max-width: 80%;
    min-width: 30%;
    min-height: 50px;
    background-color: rgb(255, 229, 182);
    color: black;
    border-radius: 20px 20px 20px 0;
    margin-right: auto;
    padding: 8px;
    margin-left: 10px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.messageBody {
    text-indent: 0;
    display: inline;
    white-space: pre-line;
}

.prompt {
    direction: flex;
    width: 100%;
    min-height: 40px;
    max-height: 200px;
    resize: none;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    outline: none;
    text-align: left;
    cursor: auto;
}

.promptBorder {
    direction: flex;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 20px;
    outline: 1px solid #999;
    margin-bottom: 3%;
    padding: 8px;
}

.prompt::-webkit-scrollbar {
    width: 6px;
}

.prompt::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 10px;
}

.list {
    list-style-type: none;
    padding:0;
    margin-left: 0;
}

.chatHistory::-webkit-scrollbar {
    width: 6px;
}

.chatHistory::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 10px;
}

.AITyping {
    color: white;
    margin-right: auto;
    padding-left: 10px;
    height: 5%;
}