@media (prefers-reduced-motion: no-preference) {
  .appLogo {
    animation: fadeIn infinite 3s linear;
    width: 40vh;
    height: 40vh;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.appBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: radial-gradient(circle, #F5F5F5, #D0D0D0);
}

.loadingScreen {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle, #F5F5F5, #D0D0D0);
}