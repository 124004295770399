.sentMessage {
    max-width: 80%;
    min-width: 30%;
    min-height: 50px;
    background-color: rgb(188, 238, 255);
    color: black;
    border-radius: 20px 20px 0 20px;
    margin-left: auto;
    text-align: left;
    padding: 8px;
    margin-right: 10px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.receivedMessage {
    max-width: 80%;
    min-width: 30%;
    min-height: 50px;
    background-color: rgb(255, 229, 182);
    color: black;
    border-radius: 20px 20px 20px 0;
    margin-right: auto;
    padding: 8px;
    margin-left: 10px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.messageBody {
    text-indent: 0;
    display: inline;
    white-space: pre-line;
}

.citation {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px;
}

.citation:hover {
    background-color: rgb(255, 220, 156);
    cursor: pointer;
}

.citationsDropdown {
    display: flex;
    justify-content: right;
}

.citationsDropdownLabel {
    padding: 1%;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 0, 0, 0);
    color: rgb(94, 93, 93);
    font-size: smaller;
}

.citationsDropdownLabel:hover {
    background-color: rgb(252, 216, 150);
    cursor: pointer;
}

.citationsList {
    display: none;
}

.citationsListExpanded {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-indent: 3%;
}