.navPane {
    width: 50px;
    background-color: #333;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100vh;
    transition: .03s;
    box-shadow: 2px 3px 20px #333;
}
.navPaneDetails {
    height: 65%;
    display: flex;
    flex-direction: row;
}

.navPane li {
    padding-top: 10px;
    color: white;
}

.navPaneExpanded {
    width: 250px;
}

.detailsColumn {
    flex: 1;
    text-align: left;
    display: none;
    background-color: #333;
    overflow-y: auto;
    overflow-x: hidden;
    list-style-type: none;
}

.detailsColumnExpanded {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
}

.detailsColumn li {
    padding: 0;
}

.detailsColumn::-webkit-scrollbar {
    width: 6px;
}

.detailsColumn::-webkit-scrollbar-thumb {
    background-color: #222;
    border-radius: 10px;
}

.arrowColumn {
    width: 50px;
    background-color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.arrow {
    padding: 10px;
    color: white;
    font-size: 20px;
}

.arrowButton {
    text-align: center;
    transition: background-color 0.2s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
}

.arrowButton:hover {
    background-color: #555;
    cursor: pointer;
}

.header {
    height: 10%;
    margin-left: 20px;
    margin-right: 20px;
    border: none;
}

.header img {
    max-width: 100%;
    max-height: auto;
    display: none;
}

.headerExpanded {
    border-bottom: 2px solid #444;
}

.headerExpanded img {
    display: flex;
}

.miniSpacer {
    height: 5%;
}

.footer {
    margin-left: 20px;
    margin-right: 20px;
    height: 15%;
}

.footerExpanded {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #444;
    color: white;
}

.footerContent {
    margin-top: auto;
    display: none;
}

.footerContentExpanded {
    display: flex;
    flex-direction: column;
}