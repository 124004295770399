.uploadBtn {
    width: 20%;
    height: 40%;
    background-color: white;
    color: #666666;
    font-size: 17px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #cecdcd;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: background-color 0.2s ease;
}

.uploadBtn:hover {
    background-color: #fff7ea;
    cursor: pointer;
}

.uploadBtn input {
    display: none;
}