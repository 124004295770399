.navButton {
    font-size: 20px;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.2s ease;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}

.navButtonChild {
    padding-left: 20px;
    font-size:18px;
}

.navButtonSelected {
    background-color: #222;
}

.navButton:hover {
    background-color: #555;
}