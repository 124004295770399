.outer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contentBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    height: 95%;
    background-color: #333;
    margin: 0.5%;
    border: 2px solid #333;
    border-radius: 30px;
    box-shadow: 2px 3px 20px #333;
}
.headerButton {
    margin-left: 70%;
    width: 20%;
    height: 5%;
    border: none;
    border-radius: 20px;
    background-color: #333;
    font-weight: 444;
    color: white;
    font-size: 15px;
    transition: background-color 0.2s ease;
}

.headerButton:hover {
    background-color: #555;
    cursor: pointer;
}


.pdfBox {
    background-color: #f8f8f8;
    border: none;
    border-radius: 20px;
    height: 93%;
    width: 95%;
    overflow: hidden;
}

.spacer {
    height: 5%;
    margin: auto;
}

.miniSpacer {
    height: 2%;
    margin: auto;
}

.pdfNotSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/corl-pattern.jpg');
    height: 100%;
    width: 100%;
}

.pdfNotSelected p {
    margin: 5%;
}

.uploadPdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFEFC;
    color: black;
    border-radius: 5px;
    height: 30%;
    width: 50%;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 2px 3px 6px #444;
}

.uploadPdf button {
    width: 70%;
    height: 25%;
    background-color: white;
    color: #666666;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #cecdcd;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.uploadPdf button:hover {
    background-color: #fff7ea;
    cursor: pointer;
}

.chatBox {
    background-image: url('../../assets/images/corl-pattern.jpg');
    border-radius: 20px;
    height: 93%;
    width: 95%;
    display: flex;
    justify-content: center;
}

.messageBox {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 20px;
    height: 5%;
    width: 95%;
    margin-top: auto;
    margin-bottom: 15px;
    text-align: left;
    padding: 8px;
}