@media (prefers-reduced-motion: no-preference) {
    .outer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.1s linear;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.inner {
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 70%;
    padding: 20px;
    border-radius: 10px;
}

.header {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header p {
    font-weight: 555;
    font-size: 20px;
}

.selectBox {
    width: 100%;
    height: 90%;
    align-items: center;
    background-color:  #cecdcd;
    border-radius: 5px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.selectBox::-webkit-scrollbar {
    width: 5px;
}

.selectBox::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 10px;
}

.pdfItem {
    width: 97%;
    height: 8%;
    background-color: #f7f7f7;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    border-radius: 5px;
    font-weight: 555;
    display: flex;
    align-items: center;
    padding-left: 1%;
    transition: background-color 0.2s ease;
}

.pdfItem:hover {
    background-color: #fff7ea;
    cursor: pointer;
}