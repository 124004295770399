.outer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background: radial-gradient(circle, #F5F5F5, #D0D0D0);
}

.inner {
    width: 45vh;
    height: 60vh;
    background-color: #444;
    border-radius: 5px;
    box-shadow: 2px 3px 20px #888;
}

.innerContent {
    padding: 50px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.innerContent img {
    margin-top: 15px;
    max-width: 50%;
    max-height: auto;
}

.innerContent h1 {
    font-size: 25px;
    font-weight: normal;
}