a {
    color: inherit;
    text-decoration: none;
}

.parentOption {
    list-style-type: none;
}

.parentOptionLabel {
    font-size: 20px;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.2s ease;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.parentOptionLabel:hover {
    background-color: #555;
}

.dropdownMenu {
    padding: 0;
    list-style-type: none;
}